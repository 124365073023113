export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{props.title}</h2>
          <p>{props.paragraph}</p>
        </div>
        <div className="row">
          {props.list?.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4">
              <i className={d.icon}></i>
              <div className="service-desc">
                <h3>{d.name}</h3>
                <p>{d.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
