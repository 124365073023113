import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export const Gallery = (props) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);

  const openImageViewer = useCallback((arrayOfImages) => {
    setImages(arrayOfImages);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
    setImages([]);
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{props.title}</h2>
          <p>{props.paragraph}</p>
        </div>
        {props.list && (
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry gutter="20px">
              {props.list?.map(({ title, cover, images }, index) => (
                <div key={index} onClick={() => openImageViewer(images)}>
                  <div className="portfolio-item cursor">
                    <div className="hover-bg">
                      <div className="hover-text">
                        <h4>{title}</h4>
                      </div>
                      <img src={cover} className="img-responsive" alt={title} />
                    </div>
                  </div>
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        )}

        {isViewerOpen && (
          <ImageViewer
            src={images}
            backgroundStyle={{ zIndex: 99999 }}
            onClose={closeImageViewer}
          />
        )}
      </div>
    </div>
  );
};
