export const Footer = (props) => {
  return (
    <div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; {new Date().getFullYear()} {props.data?.company} - Designed
            by{" "}
            <a href="https://github.com/issaafalkattan" rel="nofollow">
              Issaaf Kattan
            </a>
            {" - "}
            <a href="https://www.vecteezy.com/free-photos" rel="nofollow">
              Free Stock photos
            </a>{" and "}
            <a
              href="https://www.vecteezy.com/free-vector/avatar"
              rel="nofollow"
            >
              Avatar Vectors
            </a>{" "}
            by <a href="https://www.vecteezy.com">Vecteezy</a>
          </p>
        </div>
      </div>
    </div>
  );
};
